import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import SEO from '../../components/Seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO description="Instructions to install TypeScript with Zeit Next.js." title="Zeit Next.js" keywords={['zeit, next, nextjs, next.js']} mdxType="SEO" />
    <h1 {...{
      "id": "zeit-nextjs"
    }}>{`Zeit Next.js`}</h1>
    <p>{`For additional details, please refer to `}<a parentName="p" {...{
        "href": "https://github.com/zeit/next-plugins/tree/master/packages/next-typescript"
      }}>{`Next.js + Typescript documentation`}</a>{`.`}</p>
    <p>{`1`}{`.`}{` In an existing, Zeit.js project, install `}<inlineCode parentName="p">{`@zeit/next-typescript`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`npm install --save @zeit/next-typescript
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{}}>{`yarn add @zeit/next-typescript
`}</code></pre>
    <p>{`2`}{`.`}{` Create or edit `}<inlineCode parentName="p">{`next.config.js`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// new next.config.js
const withTypescript = require('@zeit/next-typescript');
module.exports = withTypescript();
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// existing next.config.js
const withTypescript = require('@zeit/next-typescript');
module.exports = withTypescript({
  webpack(config, options) {
    return config;
  },
});
`}</code></pre>
    <p>{`3`}{`.`}{` Create or edit `}<inlineCode parentName="p">{`.babelrc`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "presets": ["next/babel", "@zeit/next-typescript/babel"]
}
`}</code></pre>
    <p>{`4`}{`.`}{` Create a `}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/docs/handbook/tsconfig-json.html"
      }}><inlineCode parentName="a">{`tsconfig.json`}</inlineCode></a>{` in the root of your project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "compilerOptions": {
    "allowJs": true,
    "allowSyntheticDefaultImports": true,
    "jsx": "preserve",
    "lib": ["dom", "es2017"],
    "module": "esnext",
    "moduleResolution": "node",
    "noEmit": true,
    "noUnusedLocals": true,
    "noUnusedParameters": true,
    "preserveConstEnums": true,
    "removeComments": false,
    "skipLibCheck": true,
    "sourceMap": true,
    "strict": true,
    "target": "esnext"
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      